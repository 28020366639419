import React from 'react';

import './hamburger.css';

export default function Hamburger() {
  return (
    <div
      style={{
        position: `relative`,
        width: `30px`,
        height: `26px`,
      }}
    >
      <div
        className="hamburgerIcon"
        style={{
          fontSize: 0,
          color: `transparent`,
        }}
      >
        <span />
        Menu
      </div>
    </div>
  );
}
